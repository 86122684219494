<template>
    <div class="icon-library-preview-box" @click="selectIcon" v-html="val">
    </div>
</template>

<script>

    export default {
        props:['value','colorized','url'],
        data:( () => ({
            val:''
        })),
        watch:{
            val(e){
                this.$emit('input',e)
            }
        },
        methods:{
            confirm(e){
                if (this.url) return this.val = `https://res.bildhive.${window.location.href.includes('bildhive.com') ? 'com' : 'dev'}${e.path}`
                if (!e.data) return console.error('No icon Selected')
                return this.val = e.data
            },
            selectIcon(){
                this.$store.commit('LIBRARY_SELECT', {type:'icons',callback:this.confirm,colorized:this.colorized,url:this.url})
            }
        },
        created(){
            this.val = this.value
        }

    }

</script>

<style>

</style>
