<template>
  <div>
        <a-modal :visible="featureModal.show" :closable="true"  @cancel="featureModal.show = false" @ok="handleFeatureSubmit">
            <div>
                <h5 class="mb-3">{{featureModal.edit ? 'Edit' : 'New'}} Feature</h5>
                <div class="feature-modal-content">
                    <a-form>
                        <a-form-item label="Feature Name" required  :validate-status="showError && !featureModalObj.name.trim() ? 'error' : ''" :help="showError&& `Name is required`">
                            <a-input v-model="featureModalObj.name" />
                        </a-form-item>
                        <div class="dF" style="gap:2ch">
                            <a-form-item label="Thumbnail" required :validate-status="showError && !featureModalObj.thumbnail.trim() ? 'error' : ''" :help="showError&& `Thumbnail Required!`">
                                <ImageBoxSelector :key="newTime" v-model="featureModalObj.thumbnail" />
                            </a-form-item>
                            <a-form-item label="Main Image">
                                <ImageBoxSelector :key="newTime" v-model="featureModalObj.image" />
                            </a-form-item>
                        </div>
                        <a-form-item label="Body Content" :validate-status="showError && !featureModalObj.description.trim() ? 'error' : ''" :help="showError && `An Image or a description is required`" required>
                            <RichText :key="newTime" v-model="featureModalObj.description" :options="{bullets:true,sizeToggle:true}" size="large" :variable="false" />
                        </a-form-item>
                    </a-form>
                    
                </div>
             
            </div>
        </a-modal>

        <div v-if="featureBlocks && featureBlocks.length" class="mb-2 features-list-wrapper">

                <div  v-for="(feat,featI) in featureBlocks" :key="`feat${featI}`" style="line-height:1.5;" class="cursor-pointer features-list-item">
                    {{featI + 1}}. <span class="hover:underline" @click="editFeature(feat.id)">{{feat.name}}</span> <a-icon @click="removeFeature(featI)" type="delete" class="hover:text-primary" style="margin-left:1ch;" />
                </div>
                    
        </div>
        <div v-else class="empty-blocks py-1" style="color:#ccc; font-size:14px">
            No Features Added
        </div>
        <template>
            <a href="#" class="dF" style="gap:1ch; line-height:1; text-decoration:underline" @click.prevent="addFeature"><a-icon type="plus-circle" />Add Feature</a>
        </template>
       
    </div>
</template>

<script>
import IconSelect from '@/components/common/IconSelect'
import {setProp,deleteProp} from 'bh-mod'
export default {
    props:{
        value:{
            type:Array,
            default:() => ( [] )
        }
    },
    components:{
        IconSelect,
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
        RichText: () => import('bh-mod/components/common/RichText'),
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data:( () => ({
        newTime:Date.now(),
        showError:false,
        featureBlocks:[],
        limitReached:false,
        featureModalObj:{
            name:'',
            description:'',
            thumbnail:'',
            image:'',
        },
        featureModal:{
            show:false,
            edit:false
        },
        activeKey:'0',
    })),
    watch:{
        featureBlocks:{
            deep:true,
            handler(val){
                this.$emit('input',val)
            }
        },
        'featureModal.edit'(val){
            if (val){
                let found = this.featureBlocks.find(x => x.id === val)
                if (found){
                    let {name,description,thumbnail,image = ''} = found 
                    this.featureModalObj = {name,description,thumbnail,image}
                    this.featureModal.show = true
                    

                } else {
                    this.$message.error('Sorry could not find the feature to edit')
                    this.featureModal.edit = ''
                }
            }
            this.newTime = Date.now()
        },
        'featureModal.show'(val){
            if (!val) {
                this.featureModal.edit = ''
                this.showError = false
                setProp(this,['featureModalObj'], {
                    name:'',
                    description:'',
                    thumbnail:'',
                    image:'',
                })
                
                this.newTime = Date.now()
            }
        },
        
    },
    computed:{},
    methods:{
        handleFeatureSubmit(){

            let {name,thumbnail,image = '',description} = this.featureModalObj
            let str = description.replace(/(<\/{0,1}p>)/g,'')
            str = str.replace(/(<\/{0,1}h2>)/g,'')
            str = str.replace(/(<\/{0,1}li>)/g,'')
            str = str.replace(/(<\/{0,1}ul>)/g,'')
            str = str.trim()
            if (!str) description = ''


            let id = this.featureModal.edit

            if (!name.trim() || !thumbnail.trim() || (!str && !image.trim())) return this.showError = true

            if (id){

                this.featureBlocks = this.featureBlocks.map(item => {
                    return (item.id === this.featureModal.edit) ? {id,name,thumbnail,image,description} :  item
                })

            } else {
                this.featureBlocks.push({
                    name,thumbnail,description,image,id:name[0] + Date.now() + name[name.length-1]
                })
            }
            this.featureModal.show = false
        },
        addFeature(){
            this.featureModal.show = true
        },
        editFeature(id){

            this.featureModal.edit = id

        },
        removeFeature(i){
            deleteProp(this,['featureBlocks',i])
        }
    },
    created(){
        this.featureBlocks = JSON.parse(JSON.stringify(this.value))
    }
}
</script>
<style lang="scss" scoped>
    .features-list-item{
        &:not(:hover) >  i {
            display:none;
        }
    
    }
</style>